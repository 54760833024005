import { redirect } from '../../../../../../../Common/Resources/src_front/js/components/initRedirectToCheckout';
import { initQuizAnimationSearch } from '../quiz/initQuizAnimationSearch';

export const initPhoneNumberForm = () => {
    // VARS
    const locationForms = document.querySelectorAll('[data-phone-number-form]');
    if (!locationForms.length) return;

    // LISTENERS
    locationForms.forEach((form, index) => {
        form.addEventListener('submit', function (event) {
            event.preventDefault();
            const input = this.querySelector('[data-tell-input]');
            const phoneNumber = window[`phoneMask_${index}`].getNumber();
            const replacedNumber = phoneNumber.replace(/[^0-9]/g, '');
            const isNumberHasZero = replacedNumber.match(/0{7,}/);
            const countryCode = window[`phoneMask_${index}`].getSelectedCountryData().dialCode;
            const errorMessage = form.querySelector('[data-phone-error-message]');
            let isValid = false;

            // VALIDATION
            if (isNumberHasZero) {
                this.classList.add('error');
                return false;
            }

            if (window[`phoneMask_${index}`].defaultCountry === 'de') {
                if (
                    input.value.trim().length === input.placeholder.length ||
                    input.value.trim().length === input.placeholder.length - 1
                ) {
                    isValid = true;
                } else {
                    this.classList.add('error');
                    errorMessage.classList.add('is-error');
                }
            } else if (window[`phoneMask_${index}`].defaultCountry === 'my') {
                if (
                    input.value.trim().length === input.placeholder.length ||
                    input.value.trim().length === input.placeholder.length - 1 ||
                    input.value.trim().length === input.placeholder.length - 2
                ) {
                    isValid = true;
                } else {
                    this.classList.add('error');
                    errorMessage.classList.add('is-error');
                }
            } else {
                if (input.value.trim().length === input.placeholder.length) {
                    isValid = true;
                } else {
                    this.classList.add('error');
                    errorMessage.classList.add('is-error');
                }
            }

            if (isValid) {
                if (this.dataset.phoneNumberForm === 'quiz') {
                    initQuizAnimationSearch(replacedNumber);
                } else {
                    startAnimationAndSubmit(this, `+${countryCode} ${input.value}`, replacedNumber);
                }
            }
        });
    });

    // FUNCTIONS
    function startAnimationAndSubmit(form, phoneNumber, replacedNumber) {
        const hiddenTelInput = document.querySelector('[data-phone-number-input-value]');
        const popupPhoneNumber = document.querySelector('[data-popup-phone-number]');
        const popup = document.querySelector('[data-popup-location]');
        const popupForm = document.querySelector('[data-form-popup]');
        const video = document.querySelector('[data-popup-location-video]');

        video.play();
        hiddenTelInput.value = replacedNumber;
        form.classList.remove('error');
        form.classList.add('disabled');
        popupPhoneNumber.textContent = phoneNumber.trim();
        popup.classList.add('searching');
        popup.classList.add('is-opened');
        disableScroll();

        setTimeout(() => {
            popup.classList.remove('searching');
        }, 3400);

        setTimeout(() => {
            if (popupForm.dataset.planForm === 'submit') {
                popupForm.submit();
            } else {
                redirect(popupForm);
            }
        }, 4000);
    }
};
