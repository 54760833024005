import Swiper from 'swiper/bundle';
import HystModal from 'hystmodal';

export const initTestimonialsSlider = () => {
    // VARS
    const slider = document.querySelector('[data-testimonials-slider]');
    if (!slider) return;
    const hiddenClass = 'hidden';

    // INITS
    new Swiper(slider, {
        direction: 'horizontal',
        loop: false,
        spaceBetween: 30,
        grabCursor: true,
        breakpoints: {
            1280: {
                slidesPerView: 3.3,
                scrollbar: {
                    el: '[data-testimonials-slider-scrollbar]',
                    dragSize: 250,
                },
            },
            768: {
                slidesPerView: 1.8,
                spaceBetween: 40,
            },
            320: {
                slidesPerView: 1.1,
            },
        },
        navigation: {
            nextEl: '[data-testimonials-slider-button-next]',
            prevEl: '[data-testimonials-slider-button-prev]',
        },
        scrollbar: {
            el: '[data-testimonials-slider-scrollbar]',
            dragSize: 80,
        },
    });

    new HystModal({
        linkAttributeName: 'data-testimonials-slider-button',
        beforeOpen: function (modal) {
            const content = modal.openedWindow.querySelector('[data-testimonials-modal-content]');
            const clone = modal.starter.previousElementSibling.cloneNode(true);

            removeModalContent(content);
            content.append(clone);
        },
    });

    toggleButton();

    // LISTENERS
    window.addEventListener('resize', toggleButton, false);

    // FUNCTIONS
    function toggleButton() {
        const text = document.querySelectorAll('[data-testimonials-slider-text]');
        const buttons = document.querySelectorAll('[data-testimonials-slider-button]');

        [...text].forEach((text, index) => {
            const height = text.getBoundingClientRect().height;
            const scrollHeight = text.scrollHeight;

            scrollHeight > Math.ceil(height)
                ? buttons[index]?.classList.remove(hiddenClass)
                : buttons[index]?.classList.add(hiddenClass);
        });
    }

    function removeModalContent(content) {
        const childElements = content.children;

        [...childElements].forEach((elem) => {
            elem.remove();
        });
    }
};
