import { createHiddenInput } from '../../../../../../../Common/Resources/src_front/js/components/initAppendHiddenInputsToPlanForms';

export const initQuizAnimationSearch = (phoneNumber) => {
    // VARS
    const bar = document.querySelector('[data-quiz-progress-bar]');
    const lastStep = document.querySelector('[data-quiz-step="8"]');
    const animationBlock = document.querySelector('[data-quiz-animation-search]');
    const animationParts = animationBlock.querySelectorAll('[data-quiz-animation-search-part]');
    const emailForm = document.querySelector('[data-quiz-email-form]');
    const hiddenClass = 'hidden';
    let delay = 1000;

    // INITS
    window.quizState.phoneNumber = phoneNumber;

    bar.classList.add(hiddenClass);
    lastStep.classList.add(hiddenClass);
    lastStep.classList.add('prev');
    animationBlock.classList.remove(hiddenClass);
    animationBlock.classList.add('start-animation');

    [...animationParts].forEach((part, index, array) => {
        setTimeout(() => {
            part.classList.remove(hiddenClass);

            if (index === array.length - 1) {
                animationBlock.classList.add('hidden-out');
                emailForm.classList.add('visible');
                setParamHiddenInput();
            }
        }, delay);

        delay = delay + 1000;
    });
};

export function setParamHiddenInput() {
    const form = document.querySelector('[data-create-account-form]');

    form.append(createHiddenInput(['geofinder_survey', JSON.stringify(window.quizState)]));
    sessionStorage.setItem('quizState', JSON.stringify(window.quizState));
}
